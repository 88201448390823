@use '@angular/material' as mat;
@use "variables" as *;

/******************************************/
/******* ANGULAR MATERIAL OVERRIDE ********/
/******************************************/
// @use "../../node_modules/@angular/material/_theming.scss" as *;
@use "../../node_modules/@angular/material/core/theming/palettes" as *;

$hypecast-typography: mat.m2-define-typography-config(
  $font-family: $font-family-primary
);

@include mat.all-component-typographies($hypecast-typography);
@include mat.core();
// @include mat.typography-hierarchy($hypecast-typography);

$primary-palette: (
  50: #d3d3d3,
  100: #d3d3d3,
  200: #d3d3d3,
  300: #46bcf7,
  400: #40ade4,
  500: #3b9ed0,
  600: #3b9ed0,
  700: #3b9ed0,
  800: #3b9ed0,
  900: #3b9ed0,
  A100: #cccccc,
  A200: #3b9ed0,
  A400: #3b9ed0,
  A700: #3b9ed0,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: rgba(white, 0.87),
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white
  )
);
$hypecast-app-primary: mat.m2-define-palette($primary-palette, A200, A100, A400);
$hypecast-app-accent: mat.m2-define-palette(mat.$m2-indigo-palette, A200, A100, A400);
$hypecast-app-warn: mat.m2-define-palette(mat.$m2-red-palette);
$hypecast-app-theme: mat.m2-define-light-theme(
  $hypecast-app-primary,
  $hypecast-app-accent,
  $hypecast-app-warn
);
@include mat.all-component-themes($hypecast-app-theme);
/* **************************************** */
/* *********** HYPECAST THEME ************ */
/* **************************************** */
/* * FONTS * */
@font-face {
  font-family: $font-family-primary;
  font-weight: normal;
  font-style: normal;
  src: url("/assets/fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: $font-family-bold;
  font-weight: bold;
  font-style: normal;
  src: url("/assets/fonts/Montserrat-Medium.ttf") format("truetype");
}
/* * CONTAINER * */
.container {
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
}
@media (min-width: $media-query-gt-xs) {
  .container {
    width: 100%;
    width: 556px;
  }
}
@media (min-width: $media-query-gt-sm) {
  .container {
    width: 942px;
  }
}
@media (min-width: $media-query-gt-md) {
  .container {
    width: 1220px;
  }
}
/* * HELPER * */
.#{$namespace}-full-width {
  width: 100%;
}
.#{$namespace}-text-uppercase {
  text-transform: uppercase;
}
* {
  box-sizing: border-box;
}
/* * HTML, BODY * */
html,
body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: $background-color-primary;
  margin: 0px;
  font-family: $font-family-primary;
  color: $font-color-secondary;
  font-size: $font-size-p-desktop;
  line-height: $line-height-p-desktop;
}
/* * TYPINGS * */
h1,
h2,
h3,
h4 {
  font-weight: normal;
  font-family: $font-family-primary;
  color: $font-color-secondary;
  margin: 0px;
}
h1 {
  font-size: $font-size-h1-mobile;
  letter-spacing: $letter-spacing-h1-mobile;
  line-height: $line-height-h1-mobile;
}
h2 {
  font-size: $font-size-h2-mobile;
  letter-spacing: $letter-spacing-h2-mobile;
  line-height: $line-height-h2-mobile;
}
h3 {
  font-size: $font-size-h3-mobile;
  letter-spacing: $letter-spacing-h3-mobile;
  line-height: $line-height-h3-mobile;
}
h4 {
  font-size: $font-size-h4-mobile;
  letter-spacing: $letter-spacing-h4-mobile;
  font-family: $font-family-primary;
  line-height: $line-height-h4-mobile;
  font-weight: bold;
  text-transform: uppercase;
}
.accented {
  font-family: $font-family-primary;
  font-weight: bold;
}
a {
  text-decoration: underline;
  font-family: $font-family-primary;
  color: $font-color-secondary;
  transition: color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    cursor: pointer;
    color: $font-color-hover;
  }
}
p {
  font-family: $font-family-primary;
  color: $font-color-secondary;
  font-size: $font-size-p-mobile;
  letter-spacing: $letter-spacing-p-mobile;
  line-height: $line-height-p-mobile;
  margin: 0px;
  &.accented,
  .accented {
    font-family: $font-family-bold;
  }
  a {
    @extend p;
    text-decoration: underline;
    transition: color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
      color: $font-color-hover;
    }
  }
  &.small {
    font-family: $font-family-primary;
    color: $font-color-secondary;
    font-size: $font-size-p-small-mobile;
    letter-spacing: $letter-spacing-p-small-mobile;
    line-height: $line-height-p-small-mobile;
    margin: 0px;
    &.accented,
    .accented {
      font-family: $font-family-bold;
    }
    a {
      @extend p, .small;
      text-decoration: underline;
      transition: color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      &:hover {
        color: $font-color-hover;
      }
    }
  }
}
.#{$namespace}-foot-note {
  font-family: $font-family-primary;
  color: $font-color-secondary;
  font-size: $font-size-foot-note-desktop;
  letter-spacing: $letter-spacing-foot-note-desktop;
  line-height: $line-height-foot-note-desktop;
  margin: 0px;
  &.accented,
  .accented {
    font-weight: bold;
  }
  a {
    @extend .#{$namespace}-foot-note;
    text-decoration: underline;
    &:hover {
      color: $font-color-primary;
    }
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: $font-size-h1-medium;
    letter-spacing: $letter-spacing-h1-medium;
    line-height: $line-height-h1-medium;
  }
  h2 {
    font-size: $font-size-h2-medium;
    letter-spacing: $letter-spacing-h2-medium;
    line-height: $line-height-h2-medium;
  }
  h3 {
    font-size: $font-size-h3-medium;
    letter-spacing: $letter-spacing-h3-medium;
    line-height: $line-height-h3-medium;
  }
  h4 {
    font-size: $font-size-h4-medium;
    letter-spacing: $letter-spacing-h4-medium;
    line-height: $line-height-h4-medium;
  }
  p {
    font-size: $font-size-p-medium;
    letter-spacing: $letter-spacing-p-medium;
    line-height: $line-height-p-medium;
    &.small {
      font-size: $font-size-p-small-medium;
      letter-spacing: $letter-spacing-p-small-medium;
      line-height: $line-height-p-small-medium;
    }
  }
  .#{$namespace}-foot-note {
    font-size: $font-size-foot-note-medium;
    letter-spacing: $letter-spacing-foot-note-medium;
    line-height: $line-height-foot-note-medium;
  }
}
@media (min-width: 1281px) {
  h1 {
    font-size: $font-size-h1-desktop;
    letter-spacing: $letter-spacing-h1-desktop;
    line-height: $line-height-h1-desktop;
  }
  h2 {
    font-size: $font-size-h2-desktop;
    letter-spacing: $letter-spacing-h2-desktop;
    line-height: $line-height-h2-desktop;
  }
  h3 {
    font-size: $font-size-h3-desktop;
    letter-spacing: $letter-spacing-h3-desktop;
    line-height: $line-height-h3-desktop;
  }
  h4 {
    font-size: $font-size-h4-desktop;
    letter-spacing: $letter-spacing-h4-desktop;
    line-height: $line-height-h4-desktop;
  }
  p {
    font-size: $font-size-p-desktop;
    letter-spacing: $letter-spacing-p-desktop;
    line-height: $line-height-p-desktop;
    &.small {
      font-size: $font-size-p-small-desktop;
      letter-spacing: $letter-spacing-p-small-desktop;
      line-height: $line-height-p-small-desktop;
    }
  }
  .#{$namespace}-foot-note {
    font-size: $font-size-foot-note-mobile;
    letter-spacing: $letter-spacing-foot-note-mobile;
    line-height: $line-height-foot-note-mobile;
  }
}
/* * BUTTONS * */
.mat-flat-button.mat-default {
  border: 1px solid rgba(0, 0, 0, 0.14);
  transition: background-color 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
  &[disabled] {
    background-color: white;
  }
}
.mat-flat-button.mat-primary {
  transition: background-color 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  background-color: #aa8c4e;
  &:hover {
    background-color: #9e8249;
  }
  &[disabled] {
    background-color: rgba(0, 0, 0, 0.03);
  }
}
button.mat-raised-button {
  border-radius: 0px;
  box-shadow: none !important;
  font-family: $font-family-primary;
  font-size: $font-size-foot-note-desktop;
  letter-spacing: 2.3px;
  line-height: $line-height-h4-desktop;
  min-width: 250px;
  height: 46px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 12px;
  padding-top: 12px;
  text-transform: uppercase;
  &.mat-primary {
    &:hover {
      background-color: $background-color-hover;
    }
    &[disabled] {
      background-color: $background-color-disabled-default;
      color: $font-color-disabled-inverse;
    }
  }
  &.mat-default {
    border: 1px solid $border-color-primary;
    font-weight: bold;
    padding-top: 11px;
    padding-bottom: 11px;
    &:hover {
      background-color: $background-color-inverse;
      color: $font-color-inverse;
      font-weight: normal;
    }
    &[disabled] {
      background-color: $background-color-primary;
      border: 1px solid $font-color-disabled-default;
      color: $font-color-disabled-default;
      font-weight: normal;
    }
  }
}
